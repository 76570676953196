/** @jsx jsx */

import { css } from "@chakra-ui/react";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import { jsx } from "@emotion/react";
import { ReactElement } from "react";

deckDeckGoHighlightElement();

export interface HtmlProseProps {
  html: string;
}

const styles = css`
  margin: 0;

  p {
    margin: 1rem auto;
  }

  hr {
    margin: 4rem auto;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: Recoleta-Medium;
    font-weight: var(--chakra-fontWeights-bold);
    color: var(--chakra-colors-gray-900);
  }

  h1 {
    margin-top: 2.5rem;
    font-size: var(--chakra-fontSizes-3xl);
  }

  h2 {
    margin-top: 2rem;
    font-size: var(--chakra-fontSizes-2xl);
  }

  h3 {
    margin-top: 2rem;
    font-size: var(--chakra-fontSizes-xl);
  }

  h4 {
    margin-top: 2rem;
    font-size: var(--chakra-fontSizes-lg);
  }
`;

export default function HtmlProse(props: HtmlProseProps): ReactElement {
  const { html } = props;

  return (
    <div
      css={styles}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}
